// IMPORTS
import './imports.js';

document.addEventListener('DOMContentLoaded', () => {
	App.createSearchHighlight();
	App.createFilters();
	App.createToggleFields();
	App.createBatchActions();
	App.createModalWindowsForDeleteActions();
	App.createPopovers();
	App.createTooltips();
	App.createNullableFields();
	App.createFieldsWithErrors();
	App.preventMultipleFormSubmission();

	App.setMomentLocale();
	App.setClock();
	App.prepareFields($(document));

	document.addEventListener('ea.collection.item-added', () => null);
});

const App = (() => {

	const createSearchHighlight = () => {
		const searchElement = document.querySelector('.form-action-search [name="query"]');
		if (null === searchElement) {
			return;
		}

		const searchQuery = searchElement.value;
		if ('' === searchQuery.trim()) {
			return;
		}

		const elementsToHighlight = document.querySelectorAll('table tbody td:not(.actions)');
		const highlighter = new Mark(elementsToHighlight);
		highlighter.mark(searchQuery);
	};

	const createFilters = () => {
		const filterButton = document.querySelector('.datagrid-filters .action-filters-button');
		if (null === filterButton) {
			return;
		}

		const filterModal = document.querySelector(filterButton.getAttribute('data-bs-target'));

		// this is needed to avoid errors when connection is slow
		filterButton.setAttribute('href', filterButton.getAttribute('data-href'));
		filterButton.removeAttribute('data-href');
		filterButton.classList.remove('disabled');

		filterButton.addEventListener('click', (event) => {
			const filterModalBody = filterModal.querySelector('.modal-body');
			filterModalBody.innerHTML = '<div class="fa-3x px-3 py-3 text-muted text-center"><i class="fas fa-circle-notch fa-spin"></i></div>';

			fetch(filterButton.getAttribute('href'))
				.then((response) => { return response.text(); })
				.then((text) => {
					setInnerHTMLAndRunScripts(filterModalBody, text);
				})
				.catch((error) => { console.error(error); });

			event.preventDefault();
		});

		const removeFilter = (filterField) => {
			filterField.closest('form').querySelectorAll(`input[name^="filters[${filterField.dataset.filterProperty}]"]`).forEach((filterFieldInput) => {
				filterFieldInput.remove();
			});

			filterField.remove();
		};

		document.querySelector('#modal-clear-button').addEventListener('click', () => {
			filterModal.querySelectorAll('.filter-field').forEach((filterField) => {
				removeFilter(filterField);
			});
			filterModal.querySelector('form').submit();
		});

		document.querySelector('#modal-apply-button').addEventListener('click', () => {
			filterModal.querySelectorAll('.filter-checkbox:not(:checked)').forEach((notAppliedFilter) => {
				removeFilter(notAppliedFilter.closest('.filter-field'));
			});
			filterModal.querySelector('form').submit();
		});
	};

	const createToggleFields = () => {
		const disableToggleField = (toggleField, isChecked) => {
			// in case of error, restore the original toggle field value and disable it
			toggleField.checked = isChecked;
			toggleField.disabled = true;
			toggleField.closest('.custom-switch').classList.add('disabled');
		};

		document.querySelectorAll('td.field-boolean .form-switch input[type="checkbox"]').forEach((toggleField) => {
			toggleField.addEventListener('change', () => {
				const newValue = toggleField.checked;
				const oldValue = !newValue;

				const toggleUrl = toggleField.getAttribute('data-toggle-url') + "&newValue=" + newValue.toString();
				// the XMLHttpRequest header is needed to keep compatibility with the previous code, which didn't use the Fetch API
				fetch(toggleUrl, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
					.then((response) => {
						if (!response.ok) {
							disableToggleField(toggleField, oldValue);
						}

						return response.text();
					})
					.then(() => { /* do nothing else when the toggle request is successful */ })
					.catch(() => disableToggleField(toggleField, oldValue));
			});
		});
	};

	const createBatchActions = () => {
		const selectAllCheckbox = document.querySelector('.form-batch-checkbox-all');
		if (null === selectAllCheckbox) {
			return;
		}

		const rowCheckboxes = document.querySelectorAll('input[type="checkbox"].form-batch-checkbox');
		selectAllCheckbox.addEventListener('change', () => {
			rowCheckboxes.forEach((rowCheckbox) => {
				rowCheckbox.checked = selectAllCheckbox.checked;
				rowCheckbox.dispatchEvent(new Event('change'));
			});
		});

		const deselectAllButton = document.querySelector('.deselect-batch-button');
		if (null !== deselectAllButton) {
			deselectAllButton.addEventListener('click', () => {
				selectAllCheckbox.checked = false;
				selectAllCheckbox.dispatchEvent(new Event('change'));
			});
		}

		rowCheckboxes.forEach((rowCheckbox) => {
			rowCheckbox.addEventListener('change', () => {
				const selectedRowCheckboxes = document.querySelectorAll('input[type="checkbox"].form-batch-checkbox:checked');
				const row = rowCheckbox.closest('tr');
				const content = rowCheckbox.closest('.content');

				if (rowCheckbox.checked) {
					row.classList.add('selected-row');
				} else {
					row.classList.remove('selected-row');
					selectAllCheckbox.checked = false;
				}

				const rowsAreSelected = 0 !== selectedRowCheckboxes.length;
				const contentTitle = document.querySelector('.content-header-title > .title');
				const filters = content.querySelector('.datagrid-filters');
				const globalActions = content.querySelector('.global-actions');
				const batchActions = content.querySelector('.batch-actions');

				if (null !== contentTitle) {
					contentTitle.style.visibility = rowsAreSelected ? 'hidden' : 'visible';
				}
				if (null !== filters) {
					filters.style.display = rowsAreSelected ? 'none' : 'block';
				}
				if (null !== globalActions) {
					globalActions.style.display = rowsAreSelected ? 'none' : 'block';
				}
				if (null !== batchActions) {
					batchActions.style.display = rowsAreSelected ? 'block' : 'none';
				}
			});
		});

		const modalTitle = document.querySelector('#batch-action-confirmation-title');
		const titleContentWithPlaceholders = modalTitle.textContent;

		document.querySelectorAll('[data-action-batch]').forEach((dataActionBatch) => {
			dataActionBatch.addEventListener('click', (event) => {
				event.preventDefault();

				const actionElement = event.target.tagName.toUpperCase() === 'A' ? event.target : event.target.parentNode;
				const actionName = actionElement.textContent.trim() || actionElement.getAttribute('title');
				const selectedItems = document.querySelectorAll('input[type="checkbox"].form-batch-checkbox:checked');
				modalTitle.textContent = titleContentWithPlaceholders
					.replace('%action_name%', actionName)
					.replace('%num_items%', selectedItems.length.toString());

				document.querySelector('#modal-batch-action-button').addEventListener('click', () => {
					// prevent double submission of the batch action form
					actionElement.setAttribute('disabled', 'disabled');

					const batchFormFields = {
						'batchActionName': actionElement.getAttribute('data-action-name'),
						'entityFqcn': actionElement.getAttribute('data-entity-fqcn'),
						'batchActionUrl': actionElement.getAttribute('data-action-url'),
						'batchActionCsrfToken': actionElement.getAttribute('data-action-csrf-token'),
					};
					selectedItems.forEach((item, i) => {
						batchFormFields[`batchActionEntityIds[${i}]`] = item.value;
					});

					const batchForm = document.createElement('form');
					batchForm.setAttribute('method', 'POST');
					batchForm.setAttribute('action', actionElement.getAttribute('data-action-url'));
					for (let fieldName in batchFormFields) {
						const formField = document.createElement('input');
						formField.setAttribute('type', 'hidden');
						formField.setAttribute('name', fieldName);
						formField.setAttribute('value', batchFormFields[fieldName]);
						batchForm.appendChild(formField);
					}

					document.body.appendChild(batchForm);
					batchForm.submit();
				});
			});
		});
	};

	const createModalWindowsForDeleteActions = () => {
		document.querySelectorAll('.action-delete').forEach((actionElement) => {
			actionElement.addEventListener('click', (event) => {
				event.preventDefault();

				document.querySelector('#modal-delete-button').addEventListener('click', () => {
					const deleteFormAction = actionElement.getAttribute('formaction');
					const deleteForm = document.querySelector('#delete-form');
					deleteForm.setAttribute('action', deleteFormAction);
					deleteForm.submit();
				});
			});
		});
	}

	const createPopovers = () => {
		document.querySelectorAll('[data-bs-toggle="popover"]').forEach((popoverElement) => {
			new bootstrap.Popover(popoverElement);
		});
	};

	const createTooltips = () => {
		document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((tooltipElement) => {
			new bootstrap.Tooltip(tooltipElement);
		});
	};

	const createNullableFields = () => {
		const updateNullableControlStatus = (checkbox) => {
			const formFieldIsNull = checkbox.checked;
			checkbox.closest('.form-group').querySelectorAll('select, input[type="date"], input[type="time"], input[type="datetime-local"]').forEach((dateTimeHtmlElement) => {
				dateTimeHtmlElement.disabled = formFieldIsNull;
				const dateTimeWidget = dateTimeHtmlElement.closest('.datetime-widget');
				if (null !== dateTimeWidget) {
					dateTimeWidget.style.display = formFieldIsNull ? 'none' : 'block';
				}
			});
		};

		document.querySelectorAll('.nullable-control input[type="checkbox"]').forEach((checkbox) => {
			updateNullableControlStatus(checkbox);

			checkbox.addEventListener('change', () => {
				updateNullableControlStatus(checkbox);
			});
		});
	};

	const createFieldsWithErrors = () => {
		const handleFieldsWithErrors = (form, pageName) => {
			// Adding visual feedback for invalid fields: any ".form-group" with invalid fields
			// receives "has-error" class. The class is removed on click on the ".form-group"
			// itself to support custom/complex fields.
			form.addEventListener('submit', (submitEvent) => {
				form.querySelectorAll('input,select,textarea').forEach((input) => {
					if (!input.validity.valid) {
						const formGroup = input.closest('div.form-group');
						formGroup.classList.add('has-error');

						formGroup.addEventListener('click', function onFormGroupClick() {
							formGroup.classList.remove('has-error');
							formGroup.removeEventListener('click', onFormGroupClick);
						});
					}
				});

				const eaEvent = new CustomEvent('ea.form.submit', {
					cancelable: true,
					detail: { page: pageName, form: form }
				});
				const eaEventResult = document.dispatchEvent(eaEvent);
				if (false === eaEventResult) {
					submitEvent.preventDefault();
					submitEvent.stopPropagation();
				}
			});
		};

		['.ea-new-form', '.ea-edit-form'].forEach((formSelector) => {
			const form = document.querySelector(formSelector);
			if (null !== form) {
				handleFieldsWithErrors(form, formSelector.includes('-new-') ? 'new' : 'edit');
			}
		});
	};

	const preventMultipleFormSubmission = () => {
		['.ea-new-form', '.ea-edit-form'].forEach((formSelector) => {
			const form = document.querySelector(formSelector);
			if (null === form) {
				return;
			}

			form.addEventListener('submit', () => {
				// this timeout is needed to include the disabled button into the submitted form
				setTimeout(() => {
					const submitButtons = form.querySelectorAll('[type="submit"]');
					submitButtons.forEach((button) => {
						button.setAttribute('disabled', 'disabled');
					});
				}, 1);
			}, false);
		});
	};

	const setMomentLocale = () => {
		moment.locale($('html').attr('lang'))
	};

	const setClock = () => {
		$(".clock").each(function () {
			var clock = $(this);
			setClockFn(clock);
			setInterval(function () {
				setClockFn(clock);
			}, 1000);
		});
	};

	// CUSTOM FIELDS
	const prepareFields = (parent) => {
		fields_inputSpinner(parent);
		fields_select2(parent);
		fields_flatpickr(parent);
		fields_tinymce(parent);
		fields_spectrumColorpicker(parent);
		fields_barrating(parent);
		fields_mask(parent);
		fields_password(parent);
		fields_range(parent);
		fields_buttonLoader(parent);
		fields_nestedCheckbox(parent);
		fields_filters();
		fields_collections();
	};

	const fields_inputSpinner = (parent) => {
		parent.find(".input-spinner").inputSpinner();
	};

	const fields_select2 = (parent) => {
		parent.find('.select2').each(function () {
			$(this).select2({
				theme: 'bootstrap-5',
				placeholder: $(this).attr('placeholder') ? $(this).attr('placeholder') : '',
				allowClear: !$(this).prop('required'),
				maximumSelectionLength: $(this).attr('max') ? $(this).attr('max') : 0,
				escapeMarkup: function (markup) { return markup; }
			});
			clearHTMLFromSelect2($(this));
			$(this).on('change', function () {
				clearHTMLFromSelect2($(this));
			});
		});
	};

	const fields_flatpickr = (parent) => {
		parent.find('.date-picker').flatpickr({
			"locale": $('html').attr('lang') == "ca" ? "cat" : $('html').attr('lang')
		});
	};

	const fields_tinymce = (parent) => {
		parent.find('.text-editor').each(function () {
			var selector = $(this).attr('id') ? '#' + $(this).attr('id') : '.' + $(this).attr('class').split(" ").join(".");
			tinymce.init({
				selector: selector,
				skin: false,
				content_css: false,
				statusbar: false,
				menubar: false,
				browser_spellcheck: true,
				quickbars_insert_toolbar: false,
				min_height: 150,
				max_height: 300,
				language: $(this).data("locale") ? $(this).data("locale").replace('-', '_') : null,
				plugins: 'advlist autolink autoresize autosave code emoticons hr image link lists media paste quickbars searchreplace table',
				toolbar: 'formatselect bold italic underline removeformat | ' +
					'fontsizeselect fontselect forecolor | ' +
					'alignleft aligncenter alignright alignjustify | ' +
					'numlist bullist | outdent indent | ' +
					'link table image media emoticons | ' +
					'undo redo | ' +
					'searchreplace code',
			});
		});
	};

	const fields_spectrumColorpicker = (parent) => {
		parent.find('.color-picker').spectrum({
			type: "text",
			preferredFormat: "hex",
			hideAfterPaletteSelect: true,
			showAlpha: false,
			showButtons: false,
			showSelectionPalette: false
		});
	};

	const fields_barrating = (parent) => {
		parent.find('.rating-star').each(function () {
			$(this).barrating({
				theme: 'css-stars',
				readonly: $(this).attr("readonly") ? $(this).attr("readonly") : false
			});
		});
		parent.find('.rating-1to10').each(function () {
			$(this).barrating({
				theme: 'bars-1to10',
				readonly: $(this).attr("readonly") ? $(this).attr("readonly") : false
			});
		});
		parent.find('.rating-movie').each(function () {
			$(this).barrating({
				theme: 'bars-movie',
				readonly: $(this).attr("readonly") ? $(this).attr("readonly") : false
			});
		});
		parent.find('.rating-square').each(function () {
			$(this).barrating({
				theme: 'bars-square',
				showValues: true,
				showSelectedRating: false,
				readonly: $(this).attr("readonly") ? $(this).attr("readonly") : false
			});
		});
		parent.find('.rating-pill').each(function () {
			$(this).barrating({
				theme: 'bars-pill',
				showValues: true,
				showSelectedRating: false,
				readonly: $(this).attr("readonly") ? $(this).attr("readonly") : false
			});
		});
		parent.find('.rating-horizontal').each(function () {
			$(this).barrating({
				theme: 'bars-horizontal',
				reverse: true,
				readonly: $(this).attr("readonly") ? $(this).attr("readonly") : false
			});
		});
	};

	const fields_mask = (parent) => {
		parent.find('[data-mask]').each(function () {
			$(this).mask($(this).attr("data-mask"));
		});
	};

	const fields_password = (parent) => {
		parent.find('[data-toggle="password"]').each(function () {
			var input = $(this);
			var eye_btn = input.parent().find('button');
			eye_btn.addClass('input-password-hide');
			eye_btn.on('click', function () {
				if (eye_btn.hasClass('input-password-hide')) {
					eye_btn.removeClass('input-password-hide').addClass('input-password-show');
					eye_btn.find('i').attr('class', 'fas fa-fw fa-eye-slash');
					input.attr('type', 'text');
				} else {
					eye_btn.removeClass('input-password-show').addClass('input-password-hide');
					eye_btn.find('i').attr('class', 'fas fa-fw fa-eye');
					input.attr('type', 'password');
				}
			});
		});
	};

	const fields_range = (parent) => {
		parent.find('.form-range').each(function () {
			$(this).parent().append($('<span>').addClass('badge rounded-pill bg-dark d-table mx-auto mt-n1'));
			$(this).next('span').html($(this).val());
			$(this).on("input", function () {
				$(this).next('span').html($(this).val());
			});
		});
	};

	const fields_buttonLoader = (parent) => {
		parent.find('.btn-loader').on("click", function () {
			$(this).buttonLoader();
		});
	};

	const fields_nestedCheckbox = (parent) => {
		parent.find('[data-checkbox-parent]').each(function () {
			$(this).nestCheckbox();
		});
	};

	const fields_filters = () => {
		$("#modal-filters").each(function () {
			const callback = function (mutationsList) {
				mutationsList.forEach(function (mutation) {
					if (mutation.addedNodes) {
						mutation.addedNodes.forEach(function (node) {
							if ($(node).is("form")) {
								prepareFields($(node));
							}
						});
					}
				});
			};
			const observer = new MutationObserver(callback);
			observer.observe($(this)[0], { attributes: true, childList: true, subtree: true });
		});
	};

	const fields_collections = () => {
		$(".field-collection.form-group").each(function () {
			const callback = function (mutationsList) {
				mutationsList.forEach(function (mutation) {
					if (mutation.addedNodes) {
						mutation.addedNodes.forEach(function (node) {
							if ($(node).is("div") && $(node).children(".form-group").length) {
								prepareFields($(node).children(".form-group"));
							}
						});
					}
				});
			};
			const observer = new MutationObserver(callback);
			observer.observe($(this)[0], { attributes: true, childList: true, subtree: true });
		});
	};

	// HELPERS
	const setInnerHTMLAndRunScripts = (element, htmlContent) => {
		// HTML5 specifies that a <script> tag inserted with innerHTML should not execute
		// https://developer.mozilla.org/en-US/docs/Web/API/Element/innerHTML#Security_considerations
		// That's why we can't use just 'innerHTML'. See https://stackoverflow.com/a/47614491/2804294
		element.innerHTML = htmlContent;
		Array.from(element.querySelectorAll('script')).forEach(oldScript => {
			const newScript = document.createElement('script');
			Array.from(oldScript.attributes).forEach(attr => newScript.setAttribute(attr.name, attr.value));
			newScript.appendChild(document.createTextNode(oldScript.innerHTML));
			oldScript.parentNode.replaceChild(newScript, oldScript);
		});
	};
	const setClockFn = (clock) => {
		var date = new Date();
		var h = date.getHours();
		var m = date.getMinutes();
		var s = date.getSeconds();
		h = (h < 10 ? "0" : "") + h;
		m = (m < 10 ? "0" : "") + m;
		s = (s < 10 ? "0" : "") + s;
		clock.find(".hours").html(h);
		clock.find(".min").html(m);
		clock.find(".sec").html(s);
	};
	const clearHTMLFromSelect2 = (el) => {
		el.siblings(".select2-container").find("[title]").each(function () {
			var previousTitle = $(this).attr("title");
			var newTitle = $.trim(previousTitle.replace(/<\/?[^>]+(>|$)/g, ""));
			$(this).attr("title", newTitle);
		});
	};

	return {
		createSearchHighlight: createSearchHighlight,
		createFilters: createFilters,
		createToggleFields: createToggleFields,
		createBatchActions: createBatchActions,
		createModalWindowsForDeleteActions: createModalWindowsForDeleteActions,
		createPopovers: createPopovers,
		createTooltips: createTooltips,
		createNullableFields: createNullableFields,
		createFieldsWithErrors: createFieldsWithErrors,
		preventMultipleFormSubmission: preventMultipleFormSubmission,
		setMomentLocale: setMomentLocale,
		setClock: setClock,
		prepareFields: prepareFields
	};
})();