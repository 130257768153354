(function ($) {
	$.fn.buttonLoader = function (options) {
		var opts = $.extend({}, $.fn.buttonLoader.defaults, options);
		return this.each(function () {
			var button = $(this);
			var actualIcon = button.find(".fas:not(.loading-icon)");
			var loadingIcon = button.find(".loading-icon");

			if (!loadingIcon.length) {
				// Validate form
				var allowLoader = true;
				if (button.is('button') && button.attr('type') == "submit") {
					var form = null;
					if (button.attr('form') !== undefined) {
						form = $('#' + button.attr('form'));
					} else {
						form = button.parents('form:first');
					}
					if (form.length && !form[0].checkValidity()) {
						allowLoader = false;
					}
				}

				if (allowLoader) {
					// Disable button
					button.attr("aria-disabled", true);
					button.addClass("disabled");

					// Add loading icon
					loadingIcon = $("<i>").addClass("fas fa-fw " + opts.icon + " fa-spin loading-icon");
					if (actualIcon.length) {
						actualIcon.css("display", "none");
						actualIcon.after(loadingIcon);
					} else {
						button.prepend(loadingIcon);
					}
				}
			} else {
				// Enable button
				button.removeAttr("aria-disabled");
				button.removeClass("disabled");

				// Remove loading icon
				loadingIcon.remove();
				if (actualIcon.length) {
					actualIcon.css("display", "");
				}
			}
			return button;
		});
	};
	$.fn.buttonLoader.defaults = {
		icon: "fa-circle-notch",
	};
})(jQuery);