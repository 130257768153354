(function ($) {
	$.fn.loader = function (options) {
		var opts = $.extend({}, $.fn.loader.defaults, options);
		return this.each(function () {
			var parent = $(this);
			var loader = parent.children(".loader");

			if (!loader.length) {
				//Add loader
				parent.css("position", "relative");
				loader = $("<div>")
					.addClass("loader position-absolute d-flex justify-content-center" + " bg-" + opts.loaderBg + " " + opts.loaderClass)
					.css("z-index", "999")
					.css("top", "0")
					.css("bottom", "0")
					.css("left", "0")
					.css("right", "0");
				var spinner = $("<div>")
					.addClass("spinner-" + opts.type + " my-auto" + " text-" + opts.spinnerColor + " " + opts.spinnerClass)
					.attr("role", "status");
				loader.prepend(spinner);
				$(this).prepend(loader);
			}

			if (opts.destroy) {
				setTimeout(function () {
					loader.fadeOut(opts.fadeOutDuration, function () {
						loader.remove();
						parent.css("position", "");
					});
				}, opts.duration);
			}

			return $(this);
		});
	};
	$.fn.loader.defaults = {
		destroy: true,
		duration: 1000,
		type: "grow",
		fadeOutDuration: "slow",
		loaderBg: "white",
		spinnerColor: "primary",
		loaderClass: null,
		spinnerClass: null,
	};
})(jQuery);