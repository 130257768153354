// JQUERY
import $ from 'jquery';
global.$ = global.jQuery = $;

// MOMENT.JS
import moment from 'moment';
global.moment = moment;
import 'moment/locale/es.js';

// BOOTSTRAP
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle';
global.bootstrap = bootstrap;

// SWEETALERT
import Swal from 'sweetalert2';
global.Swal = Swal;

// FONTAWESOME
import '@fortawesome/fontawesome-free/css/all.css';

// FLAG ICON CSS
import 'flag-icon-css/css/flag-icon.css'

// MARK HIGHLIGHT
import Mark from 'mark.js/src/vanilla';
global.Mark = Mark;

// INPUT SPINNER
import 'bootstrap-input-spinner';

// LIGHTBOX2
import 'lightbox2';
import 'lightbox2/dist/css/lightbox.css';

// SELECT2
import 'select2';
import 'select2/dist/css/select2.css';
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css';
import 'select2/dist/js/i18n/en.js';
import 'select2/dist/js/i18n/es.js';

// FLATPICKR
import 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/l10n/es.js';

// TINYMCE
import tinymce from 'tinymce';
global.tinymce = tinymce;
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/icons/default';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce-i18n/langs5/es_ES.js';

// SPECTRUM COLORPICKER
import 'spectrum-colorpicker2/dist/spectrum.js';
import 'spectrum-colorpicker2/dist/spectrum.css';

// JQUERY BAR RATING
import 'jquery-bar-rating';
import 'jquery-bar-rating/dist/themes/css-stars.css';
import 'jquery-bar-rating/dist/themes/bars-1to10.css';
import 'jquery-bar-rating/dist/themes/bars-movie.css';
import 'jquery-bar-rating/dist/themes/bars-square.css';
import 'jquery-bar-rating/dist/themes/bars-pill.css';
import 'jquery-bar-rating/dist/themes/bars-horizontal.css';

// JQUERY MASK PLUGIN
import 'jquery-mask-plugin';

// DROPZONE
import Dropzone from 'dropzone';
Dropzone.autoDiscover = false;
global.Dropzone = Dropzone;
import 'dropzone/dist/dropzone.css';

// TOASTR
import toastr from 'toastr';
toastr.options = {
	"closeButton": true,
	"debug": false,
	"newestOnTop": false,
	"progressBar": true,
	"positionClass": "toast-bottom-right",
	"preventDuplicates": true,
	"onclick": null,
	"showDuration": "300",
	"hideDuration": "1000",
	"timeOut": "5000",
	"extendedTimeOut": "1000",
	"showEasing": "swing",
	"hideEasing": "linear",
	"showMethod": "fadeIn",
	"hideMethod": "fadeOut",
	"toastClass": "toastr",
}
global.toastr = toastr;
import 'toastr/build/toastr.css';

// MEDIA
import './extra/media.js';

// LOADER
import './extra/loader.js';

// BUTTON LOADER
import './extra/buttonLoader.js';

// NESTED CHECKBOX
import './extra/nestCheckbox.js';

// CSS
import '../../css/app/app.css';
import '../../css/app/bootstrap.scss';