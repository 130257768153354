$(function () {
	// DROPZONE
	$('.dropzone-media').each(function () {
		var mediaMain = $(this).parent();
		var mediaContainer = mediaMain.find('.media-container');
		var isMediaFrom = mediaContainer.hasClass("media-form");
		var mediaFilters = mediaMain.find('.media-filters');
		var filterExt = mediaFilters.find('.media-filter-ext');
		var filterCategory = mediaFilters.find('.media-filter-category');
		$(this).dropzone({
			acceptedFiles: $(this).attr("acceptedFiles"),
			dictDefaultMessage: $(this).attr("dictDefaultMessage"),
			dictFallbackMessage: $(this).attr("dictFallbackMessage"),
			dictFallbackText: $(this).attr("dictFallbackText"),
			dictFileTooBig: $(this).attr("dictFileTooBig"),
			dictInvalidFileType: $(this).attr("dictInvalidFileType"),
			dictResponseError: $(this).attr("dictResponseError"),
			dictCancelUpload: $(this).attr("dictCancelUpload"),
			dictUploadCanceled: $(this).attr("dictUploadCanceled"),
			dictCancelUploadConfirmation: $(this).attr("dictCancelUploadConfirmation"),
			dictRemoveFile: $(this).attr("dictRemoveFile"),
			dictRemoveFileConfirmation: $(this).attr("dictRemoveFileConfirmation"),
			dictMaxFilesExceeded: $(this).attr("dictMaxFilesExceeded"),
			dictFileSizeUnits: $(this).attr("dictFileSizeUnits"),
			success: function (file, media) {
				// Remove uploaded file from dropzone
				this.removeFile(file);
				// Create Media content
				$('.media-container').prepend(
					createMediaContent(media, false, !isMediaFrom, false, false, !isMediaFrom)
				);

				//Populate new filters
				if (media.ext && !filterExt.find('option[value="' + media.ext + '"]').length) {
					var optionExt = new Option(media.ext.toUpperCase(), media.ext, false, false);
					filterExt.append(optionExt).val(null).trigger('change');
				}
				media.categories.forEach(category => {
					if (!filterCategory.find('option[value="' + category + '"]').length) {
						var optionCategory = new Option(category, category, false, false);
						filterCategory.append(optionCategory).val(null).trigger('change');
					}
				});

				//Apply filters
				applyMediaFilters(mediaContainer, mediaFilters);

				// Add Media to select
				if (isMediaFrom) {
					var modalContent = mediaMain.parent(".modal-content");
					$("#" + modalContent.data("id")).append(
						$('<option>', { value: media.id, text: media.name })
					);
				}
			}
		});
	});

	// Get medias on open modal
	$('.modal.modal-media').on('show.bs.modal', function (e) {
		var mediaContainer = $(this).find(".media-container.media-form");
		var modalContent = mediaContainer.parent(".modal-body").parent(".modal-content");
		var select = $("#" + modalContent.data("id"));
		var values = Array.isArray(select.val()) ? select.val() : [select.val()];
		var acceptedFilesParam = select.attr('acceptedFiles') ? '&acceptedFiles=' + select.attr('acceptedFiles') : '';

		var mediaFilters = $(this).find('.media-filters');
		var filterName = mediaFilters.find('.media-filter-name');
		var filterExt = mediaFilters.find('.media-filter-ext');
		var filterCategory = mediaFilters.find('.media-filter-category');
		var filterNameExclude = mediaFilters.find('.media-filter-name-exclude');
		var filterExtExclude = mediaFilters.find('.media-filter-ext-exclude');
		var filterCategoryExclude = mediaFilters.find('.media-filter-category-exclude');

		// Loader
		mediaContainer.loader({
			destroy: false
		});

		$.get("/media?type=GET" + acceptedFilesParam, function (medias) {
			for (let key = 0; key < medias.length; key++) {
				var media = medias[key];
				var isSelected = $.inArray(media.id.toString(), values) !== -1;

				//Populate filters
				if (media.ext && !filterExt.find('option[value="' + media.ext + '"]').length) {
					var optionExt = new Option(media.ext.toUpperCase(), media.ext, false, false);
					filterExt.append(optionExt).val(null).trigger('change');
				}
				media.categories.forEach(category => {
					if (!filterCategory.find('option[value="' + category + '"]').length) {
						var optionCategory = new Option(category, category, false, false);
						filterCategory.append(optionCategory).val(null).trigger('change');
					}
				});

				mediaContainer.append(
					createMediaContent(media, false, false, isSelected)
				);
			}

			mediaContainer.loader();

			//Filters
			filterName.on('input', function () {
				applyMediaFilters(mediaContainer, mediaFilters);
			});
			filterExt.on('change', function () {
				applyMediaFilters(mediaContainer, mediaFilters);
			});
			filterCategory.on('change', function () {
				applyMediaFilters(mediaContainer, mediaFilters);
			});
			filterNameExclude.on('change', function () {
				applyMediaFilters(mediaContainer, mediaFilters);
			});
			filterExtExclude.on('change', function () {
				applyMediaFilters(mediaContainer, mediaFilters);
			});
			filterCategoryExclude.on('change', function () {
				applyMediaFilters(mediaContainer, mediaFilters);
			});
		});
	});

	// Clear medias and filters on close modal
	$('.modal.modal-media').on('hidden.bs.modal', function (e) {
		var mediaContainer = $(this).find(".media-container.media-form");
		var mediaFilters = $(this).find('.media-filters');
		var filterName = mediaFilters.find('.media-filter-name');
		var filterExt = mediaFilters.find('.media-filter-ext');
		var filterCategory = mediaFilters.find('.media-filter-category');
		var filterNameExclude = mediaFilters.find('.media-filter-name-exclude');
		var filterExtExclude = mediaFilters.find('.media-filter-ext-exclude');
		var filterCategoryExclude = mediaFilters.find('.media-filter-category-exclude');

		mediaContainer.empty();

		filterName.val(null);
		filterExt.empty().trigger('change');
		filterCategory.empty().trigger('change');
		filterNameExclude.prop("checked", false);
		filterExtExclude.prop("checked", false);
		filterCategoryExclude.prop("checked", false);
	});

	// Select media in modal form
	$(".media-container.media-form").on("click", ".media-thumbnail", function () {
		var mediaContainer = $(this).parent().parent(".media-container.media-form");
		var modalContent = mediaContainer.parent(".modal-body").parent(".modal-content");
		var select = $("#" + modalContent.data("id"));
		var isMultiple = select.prop('multiple');
		var max = select.attr('max') ? select.attr('max') : 0;
		var selectedMedias = mediaContainer.find('.media-thumbnail.selected');
		if ($(this).hasClass("selected")) {
			$(this).removeClass("selected");
		} else {
			if (isMultiple) {
				if (!max || selectedMedias.length < max) {
					$(this).addClass("selected");
				}
			} else {
				if (selectedMedias.length) {
					selectedMedias.removeClass("selected");
				}
				$(this).addClass("selected");
			}
		}
	});

	// Set media in select field
	$(".modal-media").on("click", ".modal-button-choose", function () {
		var modalContent = $(this).parent(".modal-footer").parent(".modal-content");
		var mediaForm = modalContent.find(".media-container.media-form");
		var select = $("#" + modalContent.data("id"));
		var media = mediaForm.find(".media-thumbnail.selected");
		select.find('option:selected').prop("selected", false);
		media.each(function () {
			select.find("option[value='" + $(this).data("id") + "']").prop("selected", true);
		});
		$(".media-select").trigger('change');
	});

	// Update media on change select
	$(".media-select").on('change', function () {
		updateSelect($(this));
	});

	// Update media on load select
	$(".media-select").trigger('change');

	// Update medias from select
	function updateSelect(select) {
		var values = Array.isArray(select.val()) ? select.val() : [select.val()];
		var mediaSelectContainer = select.parent().find('.media-thumbnails');
		mediaSelectContainer.empty();
		for (let key = 0; key < values.length; key++) {
			var id = values[key];
			if (id) {
				$.get("/media?type=GET&id=" + id, function (media) {
					mediaSelectContainer.append(
						createMediaContent(media, true, false, false, true)
					);
				});
			}
		}
	}
	
	// Prevent filter send form on enter
	$('.media-filter-name, .media-filter-ext, .media-filter-category').on('keypress', function (e) {
		if (e.keycode == 13 || e.which == 13) {
			e.preventDefault();
		}
	});

	// MODAL MEDIA INFO
	$('[data-bs-toggle="mediaInfo"]').on("click", function () {
		$.get("/media?type=GET&id=" + $(this).data("id"), function (media) {
			modalMediaInfoLink(media);
		});
	});

	// MODAL FORMS
	$(document).on('keypress', function (e) {
		if ($('.modal.show').length && (e.keycode == 13 || e.which == 13)) {
			$('.modal.show').find('.modal-button-enter').first().trigger("click");
		}
	});
	$(".modal form").submit(function (e) {
		e.preventDefault();
		$('.modal-button-enter').trigger("click");
	});
});

/**
 * Returns jQuery element of the media.
 *
 * @param {Object} media json object of the media.
 * @param {boolean} showLink Add HTML link.
 * @param {boolean} showTitle Add a title with the media name.
 * @param {boolean} isSelected If isSelected, the media sets to selected.
 * @param {boolean} clearImage Add a clear media from field.
 * @param {boolean} imageInfo Add a link with modal media info if showLink is false.
 * @return {Object} jQuery element of the media.
 */
var cmc = function createMediaContent(media, showLink = false, showTitle = false, isSelected = false, clearImage = false, imageInfo = false) {
	var imageExtensions = ['apng', 'avif', 'bmp', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'];
	var mediaContent = $('<div>').addClass('media-content').attr('data-filter-name', media.name).attr('data-filter-ext', media.ext).attr('data-filter-categories', media.categories);
	var mediaThumbnail = $('<div>').addClass('media-thumbnail').attr('data-id', media.id);
	var link = $('<a>').attr('href', '/uploads/media/' + media.filename).addClass('stretched-link');
	var linkImageInfo = $('<a>').addClass('stretched-link').attr('data-bs-toggle', 'mediaInfo').attr('data-id', media.id);
	var title = $('<div>').addClass('media-title').attr('title', media.name).html(media.name);
	var image = $('<img>').attr('src', '/uploads/media/' + media.filename);
	var icon = $('<i>').addClass('fas fa-fw fa-file text-primary');
	var iconTxt = $('<span>').html(media.ext);
	var mediaClear = $('<div>').addClass('media-clear');

	// Select uploaded media in form
	if (isSelected) {
		mediaThumbnail.addClass('selected');
	}

	mediaContent.append(mediaThumbnail);

	// Add link
	if (showLink) {
		mediaThumbnail.append(link);
	}
	// Add title
	if (showTitle) {
		mediaContent.append(title);
	}
	// Add link to clear image
	if (clearImage) {
		mediaThumbnail.append(mediaClear);
		// Remove media in select field
		mediaThumbnail.on("click", ".media-clear", function () {
			var formWidget = mediaThumbnail.parent(".media-content").parent(".media-thumbnails").parent();
			var select = formWidget.find("select");
			select.find("option[value='" + mediaThumbnail.data("id") + "']").prop("selected", false);
			$(".media-select").trigger('change');
		});
	}
	// Add link to show media info
	if (!showLink && imageInfo) {
		mediaThumbnail.append(linkImageInfo);
		linkImageInfo.on("click", function () {
			$.get("/media?type=GET&id=" + linkImageInfo.data("id"), function (media) {
				modalMediaInfoLink(media);
			});
		});
	}

	// Image/File template
	if (imageExtensions.includes(media.ext)) {
		mediaThumbnail.append(image);
		if (showLink) {
			link.attr('data-lightbox', 'image');
		}
	} else {
		mediaThumbnail.append(icon).append(iconTxt);
		if (showLink) {
			link.attr('target', '_blank');
		}
	}

	return mediaContent;
}
global.createMediaContent = cmc;

/**
 * Applies filters on media.
 *
 * @param {Object} mediaContainer media container jQuery element.
 * @param {Object} mediaFilters filter container jQuery element.
 */
var amf = function applyMediaFilters(mediaContainer, mediaFilters) {
	var filterName = mediaFilters.find('.media-filter-name');
	var filterExt = mediaFilters.find('.media-filter-ext');
	var filterCategory = mediaFilters.find('.media-filter-category');
	var filterNameExclude = mediaFilters.find('.media-filter-name-exclude');
	var filterExtExclude = mediaFilters.find('.media-filter-ext-exclude');
	var filterCategoryExclude = mediaFilters.find('.media-filter-category-exclude');

	var name = filterName.val() ? filterName.val() : null;
	var exts = filterExt.val().length ? filterExt.val() : null;
	var categories = filterCategory.val().length ? filterCategory.val() : null;
	var nameExclude = filterNameExclude.prop('checked');
	var extsExclude = filterExtExclude.prop('checked');
	var categoriesExclude = filterCategoryExclude.prop('checked');

	if (name || exts || categories) {
		mediaContainer.find('.media-content').each(function () {
			nameCoincident = name && $(this).attr('data-filter-name').toLowerCase().indexOf(name.toLowerCase()) >= 0 ? true : false;
			extsCoincident = exts && compare($(this).attr('data-filter-ext'), exts, !extsExclude);
			categoriesCoincident = categories && compare($(this).attr('data-filter-categories').split(","), categories, !categoriesExclude);
			if (
				(!name || ((!nameExclude && nameCoincident) || (nameExclude && !nameCoincident))) &&
				(!exts || extsCoincident) &&
				(!categories || categoriesCoincident)
			) {
				$(this).show();
			} else {
				$(this).hide();
			}
		});
	} else {
		mediaContainer.find('.media-content').show();
	}

}
global.applyMediaFilters = amf;

/**
 * Compare two arrays.
 *
 * @param {Array} el1 first array to compare.
 * @param {Array} el2 second array to compare.
 * @param {boolean} equal returns if is equal or different.
 */
var c = function compare(el1, el2, equal = true) {
	arrEl1 = Array.isArray(el1) ? el1.concat().sort() : Array(el1);
	arrEl2 = Array.isArray(el2) ? el2.concat().sort() : Array(el2);

	for (i = 0; i < arrEl1.length; i++) {
		for (j = 0; j < arrEl2.length; j++) {
			if (arrEl1[i] === arrEl2[j]) {
				return equal;
			}
		}
	}
	return !equal;
}
global.compare = c;

/**
 * Show modal media info.
 *
 * @param {Object} media json object of the media.
 * @param {boolean} openModal open modal after populate.
 */
var mmil = function modalMediaInfoLink(media, openModal = true) {
	var imageExtensions = ['apng', 'avif', 'bmp', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'];
	var iframeExtensions = ['txt', 'pdf'];
	// Modal Info
	var modalInfo = $('.modal.media-info');
	var modalInfoTitle = modalInfo.find('.modal-header .modal-title');
	var modalInfoFile = modalInfo.find('.modal-body .modal-file');
	var modalInfoInfo = modalInfo.find('.modal-body .modal-info');
	// Populate modal
	modalInfo.data("id", media.id);
	modalInfoTitle.html(media.name);
	if (imageExtensions.includes(media.ext)) {
		modalInfoFile.find('img').removeClass('d-none').attr('src', '/uploads/media/' + media.filename);
		modalInfoFile.find('.embed-responsive').addClass('d-none');
		modalInfoFile.find('iframe').removeAttr('src');
		modalInfoFile.find('a').addClass('d-none').removeAttr('href');
	} else {
		modalInfoFile.find('img').addClass('d-none').removeAttr('src');
		if (iframeExtensions.includes(media.ext)) {
			modalInfoFile.find('.embed-responsive').removeClass('d-none');
			modalInfoFile.find('iframe').attr('src', '/uploads/media/' + media.filename);
		} else {
			modalInfoFile.find('.embed-responsive').addClass('d-none');
			modalInfoFile.find('iframe').removeAttr('src');
		}
		modalInfoFile.find('a').removeClass('d-none').attr('href', '/uploads/media/' + media.filename);
	}
	modalInfoInfo.find('.details .filename span').html(media.filename);
	modalInfoInfo.find('.details .mime span').html(media.mime);
	modalInfoInfo.find('.details .datetime span').html(moment(media.datetime).fromNow() + '<br />(' + moment(media.datetime).format('LLL') + ')');

	// Modal Edit
	var modalEdit = $('.modal.media-edit');
	var modalEditTitle = modalEdit.find('.modal-header .modal-title');
	var modalEditInfo = modalEdit.find('.modal-body .modal-info');
	// Populate modal
	modalEdit.data("id", media.id);
	modalEditTitle.html(media.name);
	modalEditInfo.find('#file').attr("accept", media.mime);
	modalEditInfo.find('#name').val(media.name);
	// Clear file field
	var fileField = modalEditInfo.find('#file');
	fileField.val('');

	if (openModal) {
		// Open the modal
		var bsModal = bootstrap.Modal.getOrCreateInstance(modalInfo[0]);
		bsModal.show();
	}
}
global.modalMediaInfoLink = mmil;