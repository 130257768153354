(function ($) {
	$.fn.nestCheckbox = function () {
		return this.each(function () {
			nestCheckbox($(this));

			$(this).on("change", function () {
				nestCheckbox($(this));
			});
			return $(this);
		});

		function nestCheckbox(parent) {
			var id = parent.attr('data-checkbox-parent');
			if (id) {
				var checked = parent.is(":checked");
				$('[data-checkbox-child="' + id + '"]').each(function () {
					var nestedField = $(this);
					var nestedFieldParent = nestedField.closest(".form-group");
					if (checked) {
						nestedFieldParent.show();
						//Add required
						if (nestedField.attr("required-temp")) {
							nestedField.removeAttr('required-temp');
							nestedField.prop('required', true);
						}
					} else {
						//Clear child
						if (nestedField.is("input")) {
							if (nestedField.attr('type') == "checkbox") {
								nestedField.prop('checked', false);
								nestCheckbox(nestedField);
							} else {
								nestedField.val('');
							}
						} else if (nestedField.is("select")) {
							nestedField.val(null).trigger('change');
						}
						//Remove required
						if (nestedField.prop("required")) {
							nestedField.attr('required-temp', true);
							nestedField.prop('required', false);
						}
						nestedFieldParent.hide();
					}
				});
			}
		}
	};
})(jQuery);